import React, { useMemo } from 'react';
import { M90QuoteModule } from '~/lib/data-contract';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../ModuleContainer';
import { StyledM90, StyledQuote, StyledM90Inner, StyledAuthor } from './styled';
import parse from 'html-react-parser';
import { CallToAction } from '$shared/components';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';

export type M90QuoteProps = M90QuoteModule;

export const M90Quote = ({
    quote,
    author,
    backgroundColor,
    callToAction,
    horizontalAlignment,
    pageElementIndex,
    ...rest
}: M90QuoteProps) => {
    const { textColor } = useModuleTheme({ backgroundColor });
    const quoteText = useMemo(() => {
        if (!quote) return '';

        const regex = /\*\*[{]?(.*?)[}]?\*\*/g;
        const underlinedStrArray = quote.match(regex);

        if (!underlinedStrArray) return quote;

        let formattedQuote = quote;

        underlinedStrArray.forEach((str) => {
            const replacementStr = str
                .replace('**', '<span class="underlined">')
                .replace('**', '</span>');

            formattedQuote = formattedQuote.replace(str, replacementStr);
        });

        return parse(formattedQuote);
    }, [quote]);

    return (
        <ModuleContainer
            hasGutter
            backgroundColor={backgroundColor}
            pageElementIndex={pageElementIndex}
            {...rest}
        >
            <StyledM90 textColor={textColor} contentAlignment={horizontalAlignment}>
                <StyledM90Inner contentAlignment={horizontalAlignment}>
                    <StyledQuote children={quoteText} />
                    {author && <StyledAuthor variant={'body'}>{author}</StyledAuthor>}
                    {callToAction ? (
                        <div>
                            <CallToAction callToAction={callToAction} />
                        </div>
                    ) : null}
                </StyledM90Inner>
            </StyledM90>
        </ModuleContainer>
    );
};

export default withErrorBoundary(M90Quote);
