import styled, { CSSObject } from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { Text } from '$shared/components';
import { M90QuoteProps } from './M90Quote';

type StyledM90Props = {
    textColor: string;
    contentAlignment: M90QuoteProps['horizontalAlignment'];
};

type StyledM90InnerProps = {
    contentAlignment: M90QuoteProps['horizontalAlignment'];
};

export const StyledM90 = styled.div<StyledM90Props>(
    ({ textColor }) => ({
        display: 'flex',
        flexDirection: 'row',
        color: textColor,
    }),
    ifProp(
        { contentAlignment: 'center' },
        {
            justifyContent: 'center',
        }
    ),
    ifProp(
        { contentAlignment: 'left' },
        {
            justifyContent: 'flex-start',
        }
    ),
    ifProp(
        { contentAlignment: 'right' },
        {
            justifyContent: 'flex-end',
        }
    )
);

export const StyledM90Inner = styled.div<StyledM90InnerProps>(
    ifProp({ contentAlignment: 'center' }, () => {
        return {
            maxWidth: 1200,
            textAlign: 'center',
        };
    }),
    ifProp({ contentAlignment: 'right' }, () => {
        return {
            textAlign: 'end',
        };
    })
);

export const StyledQuote = styled.blockquote(
    ({ theme }) =>
        ({
            '&, & p': {
                ...theme.fontVariants.display4,
                color: 'inherit',
                paddingBottom: theme.spaces['4'],
                [breakpoints.sm]: {
                    ...theme.fontVariants.display3,
                    paddingBottom: theme.spaces['5'],
                },
                [breakpoints.md]: {
                    paddingBottom: theme.spaces['7'],
                },
                ['.underlined']: {
                    textDecoration: 'underline',
                },
                '&:last-child': {
                    paddingBottom: 0,
                },
            },
        } as CSSObject)
);

export const StyledAuthor = styled(Text)(({ theme }) => ({
    paddingBottom: theme.spaces['4'],
    [breakpoints.sm]: {
        paddingBottom: theme.spaces['5'],
    },
    [breakpoints.md]: {
        paddingBottom: theme.spaces['7'],
    },
}));
